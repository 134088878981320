import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes =[
    //{ path: '', component: ComponentsComponent},
    
    {
      path: '',
      loadChildren: 'app/pages/pages.module#PagesModule'
    },
    {
      path: '',
      loadChildren: 'app/extra/extra.module#ExtraModule'
    },
    // { path: 'home',             component: HomeComponent },
    // { path: 'user-profile',     component: ProfileComponent },
    // { path: 'signup',           component: SignupComponent, canActivate: [NoLoggedInGuardService],},
    // { path: 'landing',          component: LandingComponent },
    // { path: 'nucleoicons',      component: NucleoiconsComponent },
    {
      path: '**',
      redirectTo: '404',
      pathMatch: 'full'
    }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
